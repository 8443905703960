<template>
  <en-drawer :model-value="modelValue" title="开票" @close="$emit('update:model-value', false)" size="40%">
    <div v-for="(item, index) of invoices.data">
      <div class="flex justify-between">
        <span>发票{{ index + 1 }}</span>
        <en-button type="primary" text @click="invoices.operation.delete.click(index)">删除</en-button>
      </div>
      <en-form :loading="invoices.loading" class="grid grid-cols-2 gap-x-6" :ref="setRef('invoices')" :rules="invoice.rules">
        <en-form-item label="发票类型" prop="invoiceTypeName">
          <select-maintain
            v-model="item.invoiceTypeName"
            :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (parmas) => (parmas.paths = ['INVCTP']) }"
            :props="{ label: 'name', value: 'name' }"
            class="w-full"
          ></select-maintain>
        </en-form-item>
        <en-form-item label="发票号码" prop="invoiceNo">
          <en-input v-model="item.invoiceNo"></en-input>
        </en-form-item>
        <en-form-item label="开票日期" prop="date">
          <en-date-picker v-model="item.date" class="w-full"></en-date-picker>
        </en-form-item>
        <en-form-item label="发票代码">
          <en-input v-model="item.invoiceSerialNo"></en-input>
        </en-form-item>
        <en-form-item label="发票抬头">
          <en-input v-model="item.invoiceTitle"></en-input>
        </en-form-item>
        <en-form-item label="开票金额">
          <en-input-number v-model="item.invoiceAmount" :min="0" :precision="2" class="w-full"></en-input-number>
        </en-form-item>
        <en-form-item label="发票照片">
          <upload-maintain v-model="item.invoiceImgUrl" folder="receipt"></upload-maintain>
        </en-form-item>
      </en-form>
    </div>

    <div>
      <en-button type="primary" text @click="invoices.operation.add.click">添加发票</en-button>
    </div>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
const invoiceDataInit = (props?: Partial<EnocloudSettlementDefinitions['InvoiceDto']>): EnocloudSettlementDefinitions['InvoiceDto'] => {
  return Object.assign({}, props) as any
}

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudSettlementDefinitions['ReceivableDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          if (this.data?.invoices.length) this.invoices.data = this.data.invoices
          else {
            await this.invoice.get()
            this.invoices.data = this.invoice.data.id ? [this.invoice.data] : [invoiceDataInit()]
          }
        } else {
          this.invoices.data = []
        }
      }
    }
  },

  config: {
    children: {
      invoice: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/customer/:customerId/invoice',
            data: 'object',
            params(params) {
              params.paths = [this.data?.payer?.id]
            }
          }
        },
        rules: {
          invoiceTypeName: [{ required: true, message: '' }],
          invoiceNo: [{ required: true, message: '' }],
          date: [{ required: true, message: '' }]
        }
      },
      invoices: {
        data: [] as EnocloudSettlementDefinitions['InvoiceDto'][],
        ajax: {
          submit: {
            action: 'PUT /enocloud/settlement/receivable/:receivableId/invoice',
            loading: true,
            params(params) {
              params.paths = [this.data?.id]
              params.data = this.invoices.data
            }
          }
        },
        children: {
          operation: {
            add: {
              click() {
                this.invoices.data.push(invoiceDataInit())
              }
            },
            delete: {
              click(index: number) {
                this.invoices.data?.splice(index, 1)
              }
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const itema = this.invoices.data.map((item) => item.invoiceTypeName)
            const itemb = this.invoices.data.map((item) => item.invoiceNo)
            const itemc = this.invoices.data.map((item) => item.date)
            const itemaa = itema.every((item) => !!item)
            const itembb = itemb.every((item) => !!item)
            const itemcc = itemc.every((item) => !!item)

            if (itemaa == false || itembb == false || itemcc === false) return

            await this.invoices.submit()
            this.emit('update:model-value', false)
          }
        }
      }
    }
  }
})
</script>
